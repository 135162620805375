import { useContext, useEffect, useState, Fragment } from "react"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { AuthContext, AuthContextType } from "../context/AuthContext"
import AddSubCategoryDialog from "./AddSubCategoryDialog"
import axios from "axios"
import { toast } from "react-hot-toast"
import CircularProgress from "@mui/material/CircularProgress";
import "../assets/style/SubCategories.css";
export function SubCategories() {
  const initialList: any = []
  const navigate = useNavigate()
  const location = useLocation()
  const [subCategories, setSubCategories] = useState(initialList)
  const showSubCategories = location.state?.showSubCategories || true;
  const [loading, setLoading] = useState(false);

  //imported states
  const { categoryName, subCategoryName } = useParams<{
    categoryName: string
    subCategoryName: string
  }>()
  const { admin } = useContext(AuthContext) as AuthContextType

  const [openAddSubCategoryDialog, setOpenAddSubCategoryDialog] =
    useState(false)

  const [category, setCategory] = useState<any>(null)
  const [editSubCategory, setEditSubCategory] = useState(null); // Add state to track the category being edited
  const handleSaveEdit = async (newName: String, editCategory: any) => {
    try {
      setLoading(true)
      await axios.post("/updateSubCategory", {
        previousName: editCategory,
        newName: newName,
        categoryName: categoryName
      });


      setEditSubCategory(null); // Close the modal
      toast.success('Subcategory Name Updated Successfully');
      getCategory()
      setLoading(false)


    } catch (error) {
      setLoading(false)

      toast.error("Failed to update sub category name");
      console.error("Error updating sub category name:", error);
    }
    finally {
      setLoading(false); // Stop loading

    }
  };


  const params = useParams()


  const getCategory = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/categories/${categoryName}`)
      setCategory(res.data)
      setSubCategories(res.data.subCategories)
      setLoading(false); //

    } catch (error) {
      setLoading(false); //

      navigate("/")

    } finally {
      setLoading(false); // Stop loading
    }
  }

  useEffect(() => {
    getCategory()
  }, [params])

  const handleClickOpen = () => {
    setOpenAddSubCategoryDialog(true)
  }


  const handleClose = () => {
    setOpenAddSubCategoryDialog(false)
    setEditSubCategory(null)
  }
  async function addSubCategory(name: string) {
    try {
      setLoading(true);

      const res = await axios.post(
        `/api/categories/${category._id}/subcategories`,
        { name }
      )
      setSubCategories([...subCategories, res.data])
      setLoading(false);

      toast.success("Category Successfully Added")
    } catch (error) {
      setLoading(false);

      toast.error((error as any)?.response?.data)
    } finally {
      setLoading(false); // Stop loading
    }
  }



  const handleDeleteSubCategory = async (subCategoryIdToDelete: any) => {
    try {
      setLoading(true);

      await axios.delete(`/api/subcategories/${subCategoryIdToDelete}`)
      // Remove the deleted subcategory from the subcategory array
      setLoading(false); //

      setSubCategories(
        subCategories.filter(
          (subCategory: any) => subCategory._id !== subCategoryIdToDelete
        )
      )
    } catch (error) {
      setLoading(false); //

      console.error("Error deleting subcategory:", error)
    } finally {
      setLoading(false); // Stop loading
    }
  }

  return (
    <>


      {showSubCategories && (
        <Box sx={{ display: "flex", padding: 0 }}>
          {/* <CssBaseline /> */}
          <ul style={{ width: "100%", padding: 0, marginLeft: 10, marginRight: 10 }}>
            {subCategories.map((subCategory: any) => (
              <li
                className="list-item"
                style={{ width: "100%", }}
              >
                <div style={{ display: "flex" }} >
                  <div
                    id="list-item-title"
                    className="list-item-title"
                    onClick={() =>
                      navigate(
                        `/categories/${category.name}/subcategories/${subCategory.name}`
                      )
                    }
                  >
                    {subCategory.name}
                  </div>
                </div>
                {admin && <div style={{ display: "flex" }}>
                  {admin && (
                    <button
                      onClick={() => {
                        setEditSubCategory(subCategory.name)
                      }}
                      className="edit-button"
                      onMouseEnter={(e) => {
                        ; (e.target as HTMLElement).style.backgroundColor =
                          "rgba(255, 82, 82, 0.8)"
                      }}
                      onMouseLeave={(e) => {
                        ; (e.target as HTMLElement).style.backgroundColor =
                          "transparent"
                      }}
                    >
                      Edit
                    </button>
                  )}

                  {admin && (
                    <button
                      onClick={() => handleDeleteSubCategory(subCategory._id)}
                      className="delete-button"
                      onMouseEnter={(e) => {
                        ; (e.target as HTMLElement).style.backgroundColor =
                          "rgba(255, 82, 82, 0.8)"
                      }}
                      onMouseLeave={(e) => {
                        ; (e.target as HTMLElement).style.backgroundColor =
                          "transparent"
                      }}
                    >
                      Delete
                    </button>
                  )}
                </div>}

              </li>
            ))}
          </ul>

        </Box>
      )}


      {admin && <Fragment>
        <Divider />
        <List>
          <ListItem
            onClick={handleClickOpen}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "center",
                px: 2.5
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "auto",
                  justifyContent: "center"
                }}
              >
                +
              </ListItemIcon>
              <ListItemText
                primary={"Add Subcategory"}
                sx={{ opacity: 1, marginLeft: 2 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Fragment>}
      <AddSubCategoryDialog
        addSubCategory={addSubCategory}
        open={openAddSubCategoryDialog}
        handleClose={handleClose}
        editSubCategory={editSubCategory}
      />
      <AddSubCategoryDialog
        addSubCategory={(value) => handleSaveEdit(value, editSubCategory)}
        open={Boolean(editSubCategory)}
        handleClose={handleClose}
        editSubCategory={editSubCategory}
      />

      {loading && <div
        className="loading-overlay"
      >
        <CircularProgress size={40} /> 
      </div>}

    </>
  )
}

